// Generated by Framer (a4439fa)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["zdFYqwhs2", "c0jBsaZe8"];

const serializationHash = "framer-0HBVL"

const variantClassNames = {c0jBsaZe8: "framer-v-1wgl816", zdFYqwhs2: "framer-v-1leqd7v"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transformTemplate1 = (_, t) => `translateY(-50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "zdFYqwhs2", "Variant 2": "c0jBsaZe8"}

const getProps = ({height, id, text, width, ...props}) => { return {...props, oUS85bH7m: text ?? props.oUS85bH7m ?? "Apply to join Paris beta 💌", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "zdFYqwhs2"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;text?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, oUS85bH7m, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "zdFYqwhs2", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1leqd7v", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"zdFYqwhs2"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({c0jBsaZe8: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7TWFucm9wZS1yZWd1bGFy", "--framer-font-family": "\"Manrope\", \"Manrope Placeholder\", sans-serif"}}>Apply to join <motion.span style={{"--font-selector": "R0Y7TWFucm9wZS02MDA=", "--framer-font-weight": "600"}}>Paris</motion.span> beta 💌</motion.p></React.Fragment>} className={"framer-ovml3o"} fonts={["GF;Manrope-regular", "GF;Manrope-600"]} layoutDependency={layoutDependency} layoutId={"EMTMEJJay"} style={{"--framer-paragraph-spacing": "0px"}} text={oUS85bH7m} transformTemplate={transformTemplate1} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({c0jBsaZe8: {children: <React.Fragment><motion.p style={{"--font-selector": "R0Y7TWFucm9wZS1yZWd1bGFy", "--framer-font-family": "\"Manrope\", \"Manrope Placeholder\", sans-serif"}}>Apply to join <motion.span style={{"--font-selector": "R0Y7TWFucm9wZS02MDA=", "--framer-font-weight": "600"}}>Berlin</motion.span> beta 💌</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-0HBVL.framer-k4o2y2, .framer-0HBVL .framer-k4o2y2 { display: block; }", ".framer-0HBVL.framer-1leqd7v { height: 19px; overflow: hidden; position: relative; width: 188px; }", ".framer-0HBVL .framer-ovml3o { flex: none; height: auto; left: 0px; position: absolute; top: 47%; white-space: pre; width: auto; z-index: 1; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 19
 * @framerIntrinsicWidth 188
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"c0jBsaZe8":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"oUS85bH7m":"text"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameraiKw4m37M: React.ComponentType<Props> = withCSS(Component, css, "framer-0HBVL") as typeof Component;
export default FrameraiKw4m37M;

FrameraiKw4m37M.displayName = "Paris / Berlin";

FrameraiKw4m37M.defaultProps = {height: 19, width: 188};

addPropertyControls(FrameraiKw4m37M, {variant: {options: ["zdFYqwhs2", "c0jBsaZe8"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, oUS85bH7m: {defaultValue: "Apply to join Paris beta 💌", displayTextArea: false, title: "Text", type: ControlType.String}} as any)

addFonts(FrameraiKw4m37M, [{explicitInter: true, fonts: [{family: "Manrope", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk79FO_A87jxeN7B.woff2", weight: "400"}, {family: "Manrope", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk4jE-_A87jxeN7B.woff2", weight: "600"}]}], {supportsExplicitInterCodegen: true})